import React from 'react'
import { 
	Box,
	Title,
	FillButton,
	Flex
 } from 'tailwind-react-ui'

 import IconFallback from '../../../../images/icons/icon-quicktips_white.svg'

const Facts = ({ block }) => {
	return (
		<>
			{block.map((card, i) => (
				<Flex wrap key={i} items="start" className="pt-5 sm:flex-nowrap" text="primary" data-component-type="facts">
					<Box p="0" w="14" className={`mb-5 sm:my-0 mx-auto sm:ml-0 sm:w-14 block shadow rounded-full bg-${card.bgColour ?? 'green'}`}>
						<IconFallback w="16" h="16" className={`p-4 max-w-none block h-14 w-14`} />
					</Box>
					<Box className="sm:px-7 pb-5">
						<Title level={5} className="leading-normal" text="primary">{card.heading}</Title>
						<div dangerouslySetInnerHTML={{__html: card.text}} />
						<FillButton font="display" bg="white" is="a" href={card.buttonUrl ?? (card.relatedEntry[0] ? '/' + card.relatedEntry[0].uri : null)} className="btn btn-secondary mb-0 py-2 px-6 mx-auto text-center border-primary rounded-full leading-normal"><span className="block relative">{card.buttonText ?? 'Find out more'}</span></FillButton>
					</Box>
				</Flex>
			))}
			
		</>	
	)
}

export default Facts