import React from "react";
import { 
	Box,
	FillButton,
	Flex,
	Text,
	Title
} from 'tailwind-react-ui'

export default function Modal(props) {
	const [showModal, setShowModal] = React.useState(false);

	return (
		<>
			<FillButton 
				data-type="button" font="display" bg="secondary" text="black" is="a" href={props.link}
				className="btn px-5 py-3 text-sm rounded-full mx-auto my-5"
				// style={{ transition: "all .15s ease" }}
				onClick={() => setShowModal(true)}><span className="block relative">{props.buttonText}</span></FillButton>

			{showModal ? (
				<>
					{/* {document.body.style.overflow = 'hidden'} */}
					<Flex
						 data-component-type="modal"
						 className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-modal outline-none focus:outline-none py-10"
						onClick={() => setShowModal(false)}>
						<Box className="relative w-auto m-auto max-w-3xl h-screen-3/4 overflow-y-auto rounded-lg">
							{/* Content */}
							<Flex className="border-0 rounded-lg shadow-lg relative flex-col w-full bg-white outline-none focus:outline-none">
								{/* Header */}
								<Flex items="baseline" justify="between" className="pt-5 px-5 border-b border-solid border-gray-300">
									<Title level={3} className="mb-0 leading-0">{props.heading}</Title>
									<FillButton
										font="display" text="primary" p="0"
										className="mb-2 ml-auto bg-transparent border-0 text-3xl leading-none outline-none focus:outline-none"
										onClick={() => setShowModal(false)}>×</FillButton>
								</Flex>

								{/* Body */}
								<Box className="relative p-6 flex-fill text-left">
									<Text dangerouslySetInnerHTML={{__html: props.description}} />
								</Box>
								
								{/* Footer */}
								<Box className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
									<FillButton 
										font="display" data-type="button" bg="secondary" text="black" is="a" href={props.link}  
										className="btn px-3 lg:px-8 py-3 rounded-full ml-auto"
										onClick={() => setShowModal(false)}><span className="block relative">Close</span></FillButton>
								</Box>
							</Flex>
						</Box>
					</Flex>
					<div className="opacity-80 fixed inset-0 z-high bg-primary"></div>
				</>
			// ): document.body.style.removeProperty('overflow') }
			): '' }
			
		</>
	);
}