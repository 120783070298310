import React from 'react'
import { 
	Box,
	Flex,
	Text
} from 'tailwind-react-ui'

// Custom components
import ImgixFluidImage from '../../../ImgixFluidImage'
import Modal from '../../../Modal'
import Heading from '../BodyContent/Heading'

// Images
import Squiggle from "../../../../images/patterns/pattern-squiggle2_green.svg"

const Timeline = ({ block }) => {
	return (
		<>
			<Flex wrap justify="between" className="xl:justify-center mb-14 lg:mb-0 relative" role="tablist" data-component-type="timeline">
				
				{block.map((card, i) => (
					<>
						{/* <div className={`border-r border-gray absolute h-3/4 left-0 right-0 w-0 top-56 mt-1 mx-auto`}></div> */}
						<Box key={i} className={`group w-3/5 sm:w-80 mx-1 sm:mx-10 lg:mx-2 my-8 ${i % 2 === 0 ? 'mr-auto sm:mr-auto xl:mr-40' : 'ml-auto sm:ml-auto xl:ml-40' } time-wrapper z-1 flex flex-col text-primary border border-secondary relative transition duration-300 ease-in-out group-hover:bg-gray ${i === 0 ? 'bg-gray' : 'bg-white'}`}>
							<div className={`time-line border-t border-t-black absolute text-center text-2xl w-1/2 sm:w-36 lg:w-36 xl:w-40 h-full pb-2 top-${i % 2 === 0 ? '1/4 left-full md:left-68 lg:left-80 xl:left-80 border-r-2 border-secondary lg:ml-0' : '2/3 right-full lg:right-80 xl:right-80 border-l-2 border-secondary xl:mr-0' }`}>
								<span className={`${i === 0 ? 'text-green' : ''} font-basic -mt-12 block time-block text-xl xl:text-2xl`}><strong>{card.date}</strong></span>
								{i === 0 ?
									<Squiggle className="absolute left-0 right-0 mx-auto mt-5 top-0" />
								: null}
								<span className={`block w-8 h-8 bg-white border border-secondary rounded-full -top-4 absolute ${i % 2 === 0 ? '-right-4' : '-left-4'}`}></span>
								<span className={`block w-4 h-4 bg-secondary rounded-full -top-2 absolute ${i % 2 === 0 ? 'left-3 lg:left-2.5' : 'right-3 lg:right-2.5'}`}></span>
							</div>
							<ImgixFluidImage className="bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={3/2} />
							<Box className="z-10 block px-5 pt-5">
								<Heading
									block = {{
										heirarchy: 6,
										text     : card.date,
										colour   : "primary"
									}}
									margins = "my-0"
									className = "text-xl font-basic"
								/>
								<Text is="p" className="mb-0 text-sm mt-1">{card.summary}</Text>
							</Box>
							{card.description ?<div className="mt-auto text-center">
								<Modal
									id={card.id}
									buttonText="View more"
									heading={card.date}
									description={card.description}
								/>
							</div> : ''}
						</Box>
					</>
				))}
			</Flex>
		</>
	)
}

export default Timeline