import React from 'react'
import { 
	graphql, 
	// Link 
} from "gatsby"

// Layout
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Matrix from "../../components/Matrix"
import { buildFluidImageData } from '@imgix/gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import { 
	Box,
	Container,
	FillButton,
	Flex,
	Title,
	Section,
	Text
} from 'tailwind-react-ui'

// Custom Components
import AwardsCarousel from "../../components/Matrix/types/Independent/AwardsCarousel"
import Facts from '../../components/Matrix/types/Independent/Facts'
import ImgixFluidImage from "../../components/ImgixFluidImage"
import Heading from '../../components/Matrix/types/BodyContent/Heading'
import PageBanner from '../../components/PageBanner'
import SecondaryNav from '../../components/SecondaryNav'
// import Subscribe from '../../components/Subscribe'
import Timeline from '../../components/Matrix/types/Independent/Timeline'

// Images 
import TriangleGR from '../../images/patterns/pattern-triangles_greenred.svg'
import TriangleGY from "../../images/patterns/patterns-triangles_greenyellow.svg"

// GraphQL
export const query = graphql`
	query AboutUsPageQuery {
		entry: craftAboutUsAboutUsEntry {
			id
			remoteId
			subtitle
			title
			typeHandle
			uri
			url
			featureImage { ...UploadAssetFragment }
			displayTitle
			description
			metaTitle
			metaDescription
			metaKeywords
			metaImage { ...UploadAssetFragment }
			download {
				... on Craft_download_download_BlockType {
					id
					file {
						title
						filename
						size
						url
					}
				}
			}
			blueBlock {
				... on Craft_blueBlock_block_BlockType {
				  heading
				  subHeading
				  text
				}
			}
			imageOnly { ...UploadAssetFragment }
			timeline {
				... on Craft_timeline_year_BlockType {
					image { ...UploadAssetFragment }
					date
					summary
					description
				}
			}
			bodyContent {
				... BodyContentFragment
			}
			facts {
				... on Craft_facts_fact_BlockType {
					icon {
						id
						url
					}
					bgColour
					heading
					text
					buttonText
					buttonUrl
					relatedEntry {
						url
					}
				}
			}
			awardsContentSlider {
				... on Craft_awardsContentSlider_slide_BlockType {
					id
					image { ...UploadAssetFragment }
					heading
					text
					awards {
						url
						id
						title
						... on Craft_awards_awards_Entry {
							id
							title
							awardLogo { 
								id
								url
								width
								height
								title
							}
							description
						}
					}
				}
			}
			buttons {
				... on Craft_buttons_button_BlockType {
					text
					cutomUrl
					relatedEntry {
						uri
					}
				}
			}

		}
		siteInfo: craftSiteInformationGlobalSet {
			...SiteInfoFragment
		}
		navigation: allCraftMainNavigationRelatedEntryEntry(filter: {relatedEntry: {elemMatch: {sectionId: {eq: 18}}}}) {
            group(field: relatedEntry___sectionId) {
                nodes {
                    relatedEntry {
                        title
                        uri
                    }
                }
            }
        }
	}
`
const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const AboutUs = ({ data: { entry, siteInfo, navigation } }) => {
	
	// secondary navigation
	const secondaryNav = navigation.group[0].nodes

	const title      = entry.title || ''
	const subtitle   = entry.subtitle || siteInfo.siteTitle
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage		 = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	const [ report ] = entry.imageOnly || siteInfo.siteImage
	const reportLink = entry.buttons[0]

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>
			<h1 style={{fontSize: 0, color: 'transparent'}} className="sr-only">{metaTitle}</h1>

			<Section p="0" className="relative">
				<PageBanner
					title    = {title}
					subtitle = {subtitle}
					image    = {featureImage}
				/>
			</Section>

			<SecondaryNav items={secondaryNav} />

			<Section bg="primary relative" p="0">
				<Container className="xl:w-3/4 py-18 text-center">
					<Heading
						block = {{
							hierarchy: 2,
							text     : entry.blueBlock[0].heading,
							colour   : "white"
						}}
						margins   = "my-1"
						className = "text-4xl sm:text-5xl"
					/>
					<Heading
						block = {{
							hierarchy: 5,
							text     : entry.blueBlock[0].subHeading,
							colour   : "white"
						}}
						margins   = "mb-7"
						className = "text-xl"
					/>
					<Text
						className="text-white text-md"
						dangerouslySetInnerHTML={{
						__html: entry.blueBlock[0].text,
						}}
					/>
				</Container>
			</Section>
			
			<Section p="0">
				<div className="pt-18 px-5 lg:px-3 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto">
					<Box w="full" text="center">
						<Heading
							block = {{
								hierarchy: 3,
								text     : "Here is a snapshot of how we have developed since 1929:",
								colour   : "primary",
								align    : "center"
							}}
							margins   = "my-1"
							className = "border-b border-primary inline-block mx-auto pb-3"
						/>
					</Box>
					<Timeline
						key={entry.timeline.id}
						block={entry.timeline}
					/>
				</div>
			</Section>

			{entry.bodyContent.length ? <Section p="0" className="secondary-page gray-light">
				<Container className="xl:w-3/4 pb-14">
					<Matrix blocks={entry.bodyContent} />
				</Container>
			</Section>: '' }

			<Section bg="gray-light" p="0">
				<Container className="xl:w-3/4 py-20">
					<Title level={3} font="heading" text="primary" className="text-4xl sm:text-5xl relative flex justify-center flex-start mb-10">
						Interesting Facts
						<TriangleGY className="ml-6 -mt-1" alt="Pattern" />
					</Title>
					<div className="shadow-md p-6 sm:p-10 bg-white rounded-lg">
						<Facts block={entry.facts} />
					</div>
				</Container>
			</Section>
			
			<Section p="0">
				<Container className="lg:w-4/5 xl:w-3/4 lg:pt-10">
					<AwardsCarousel block={entry.awardsContentSlider} />
				</Container>
			</Section>
			
			<Section p="0">
				<Container className="xl:w-4/5 py-10 py:lg-14">
					<Flex wrap items="start" justify="start" className="mx-auto">
						<Box className="hidden lg:block w-1/4">
							<ImgixFluidImage className="w-full rounded-lg" alt={report.title} imageUrl={report.url} ar={report.width / report.height} focalPoint={report.focalPoint} />
						</Box>
						<Box className="pb-3 px-3 sm:px-6 w-full lg:w-3/4">
							<Title level={3} font="heading" className="text-primary relative flex justify-start flex-end">
								{entry.displayTitle}
								<TriangleGR className="sm:mx-10 -mt-2" alt="Pattern" />
							</Title>
							{entry.description ?<Text
								dangerouslySetInnerHTML={{
								__html: entry.description,
								}}
							/> : null}
							
							{/* {entry.buttons.map((block, i) => ( */}
							{reportLink ? <>
								<FillButton font="display" aria-label={reportLink.text} data-type="button" bg="secondary" bg-hocus="secondary" text="primary" is="a" href={`${reportLink.relatedEntry[0] ? '/' + reportLink.relatedEntry[0].uri : reportLink.cutomUrl}`} text-hocus="primary" className="btn px-3 lg:px-8 py-3 rounded-full ml-0 mb-3 mt-auto mr-auto hover:border-primary">
									<span className="block relative">{reportLink.text}</span>
								</FillButton><br />
							</> : null }
							<FillButton font="display" aria-label="View all the Annual Reports" data-type="button" bg="white" bg-hocus="white" text="primary" text-hocus="white" is="a" href="/about-us/newsletters-and-reports/" className="btn px-3 lg:px-8 py-3 rounded-full mb-5 border-primary">
								<span className="block relative">View all the Annual Reports</span>
							</FillButton>
						</Box>
					</Flex>
				</Container>
			</Section>
			
			{/* <Section bg="primary">
				<Container>
					<Subscribe
						title="Subscribe to our Newsletter"
						text="Sign up to our Newsletter be first to get our specials and latest news."
						linkText="Sign up to receive our Newsletter"
						url="#"
					/>
				</Container>
			</Section>  */}
		</Layout>
	)
}

export default AboutUs