import React from 'react'
import ImgixFluidImage from "../../../ImgixFluidImage"

// React multi carousel
import Carousel from 'react-multi-carousel'
// import 'react-multi-carousel/lib/styles.css'

// Tailwind React UI
import { 
	Box,
	Card,
	CardBody,
	Flex,
	Text,
	Title
} from 'tailwind-react-ui'

import ArrowLeft from "../../../../images/icons/icon-arrow_left_green.svg"
import ArrowRight from "../../../../images/icons/icon-arrow_right_green.svg"


const AwardsCarousel = ({ block }) => {
	
	const CustomLeft = ({ onClick }) => (
		<button className="absolute right-full -mr-10 arrow left px-2 py-3 shadow z-10 top-1/4 bg-white" onMouseDown={onClick} aria-label="Previous slide">
			<ArrowLeft alt="Previous slide" />  
		</button>
	)
	const CustomRight = ({ onClick }) => (
		<button className="absolute left-full -ml-10 arrow right px-2 py-3 shadow z-10 top-1/4 bg-white" onMouseDown={onClick} aria-label="Next slide">
			<ArrowRight alt="Next slide" />  
		</button>
	)

	const responsive = {
		desktop: {
			breakpoint: {
				max: 2500,
				min: 1200
			},
			items: 1,
		},
		tablet: {
			breakpoint: {
				max: 1200,
				min: 600
			},
			items: 1,
		},
		mobile: {
			breakpoint: {
				max: 600,
				min: 0
			},
			items: 1,
		} 
	}

	const CustomDot = ({ onMove, index, onClick, active }) => {
		// onMove means if dragging or swiping in progress.
		// active is provided by this lib for checking if the item is active or not.
		if (index <= 6) {
			return (
				<li key={index} className={`transition duration-300 ease-in-out bg-white w-full text-center mb-2 px-3 py-2 border focus:bg-secondary hover:bg-secondary active:bg-secondary hover:border-secondary focus:border-secondary active:border-secondary rounded-none cursor-pointer font-display ${active ? "active border-secondary bg-secondary" : "inactive"}`} >
					<button className="outline-none uppercase" onMouseDown={() => onClick()}>{block[index].heading}</button>
				</li>	
			)
		}
		return null
	}
	return (
		<>
			<Carousel
				arrows
				autoPlay	= {false}
				centerMode	= {false}
				containerClass	 = {`relative -mx-3 awardsSlider`}
				customDot		 = {<CustomDot />}
				customLeftArrow	 = {<CustomLeft />}
				customRightArrow = {<CustomRight />}
				data-component-type="awards-carousel"
				dotListClass	 = "hidden top-1/2 mt-4 bg-white lg:flex flex-wrap left-14 bottom-auto mb-6 mr-auto w-28"
				draggable	  = {false}
				focusOnSelect = {false}
				infinite
				itemClass = ""
				keyBoardControl
				minimumTouchDrag		 = {150}
				renderButtonGroupOutside = {false}
				renderDotsOutside		 = {false}
				responsive = {responsive}
				showDots
				sliderClass		= "py-5 sm:py-10"
				slidesToSlide	= {1}
				swipeable 
			>
				{block.map((card, index) => (
					<Card key={index} shadow className="card rounded-lg flex flex-col sm:h-full mx-3 shadow-md border relative" p="0">
						<Box w="full" className="mb-2 border-gray-300 overflow-hidden">
							<ImgixFluidImage className="hidden lg:block w-full bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={3.25} focalPoint={card.image[0].focalPoint} /> 
							<ImgixFluidImage className="block lg:hidden w-full bg-gray-light" alt={card.image[0].imageAlt ?? card.image[0].title ?? card.image[0].filename.replace(/[^a-z0-9+]+/gi, ' ')} imageUrl={card.image[0].url} ar={2.75} focalPoint={card.image[0].focalPoint} /> 
						</Box>
						<CardBody p="6">
							<Flex justify="start" className="lg:pl-48">
								<Box className="px-3 flex flex-col">
									<Title level={3} font="heading" className="font-heading text-primary">{card.heading}</Title>
									<Text text="primary" className="flex-fill text-lg" dangerouslySetInnerHTML={{__html: card.text}} />
									{card.awards.map((award, j) => (
										<Flex key={j} items="start" className="-ml-2">
											<Box className="px-2 py-1 w-1/6">
												<img src={award.awardLogo[0].url} alt={award.awardLogo[0].title} loading="lazy" className="m-auto max-w-full bg-gray-light" style={{maxHeight: 60, maxWidth: 75}} />
											</Box>
											<Box className="px-2 py-1 w-5/6" >
												<Text className="my-auto flex flex-align-start text-sm" text="primary" font="heading" dangerouslySetInnerHTML={{__html: `${index + 1 + '.&nbsp;' + award.description}`}} />
											</Box>
										</Flex>
									))}
								</Box>
							</Flex>
						</CardBody>
					</Card>
				))}
			</Carousel>
		</>
	)
		
}

export default AwardsCarousel